@import "design/variables";
@import 'design/template/mixin_templates';

/*-----------------------------
    GENERAL
-------------------------------*/

*, html {
  outline: 0;
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.2;
  list-style-position: inside;
  font: .75em Ubuntu, arial, sans-serif;
}

body header,
body #nav-principal,
#slider,
div#menu,
#contentpage_left div#menu {
  display: block;
}

@include titreBaseSize();

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin: 0;
  padding: 0;
}

h1, .h1 {
  font-size: 2.3em;
  font-weight: 400;
}

h2, .h2 {
  font-family: Ubuntu, arial, sans-serif;
  font-size: 1.8em;
  margin: 0 0 10px;
  padding: 0;
}

h3, .h3 {
  font-family: Ubuntu, arial, sans-serif;
}

h4, .h4 {
  margin-bottom: 10px;
  font-family: arial, sans-serif;
  font-weight: 400;
  color: #666;
  text-transform: uppercase;
}

img {
  max-width: 100%;
  height: auto !important;
}

a img {
  border: none;
}

.content .zoomPupImgWrapper > img,
.content .zoomWrapperImage img {
  max-width: none;
}

/* Calendrier réservation */
.reservation__date {
  .datepicker__month {
    width: 100%;

    &:nth-child(n+2) {
      margin-top: 20px;
    }
  }
}

.reservation__date .datepicker__months {
  max-height: none;
}

.reservation__date .datepicker__months::before {
  display: none;
}

.clearer {
  clear: both;
}

.wrapper iframe {
  max-width: 100%;
}

.button {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  margin: 5px;
  vertical-align: middle;
  border: 1px solid transparent;
}

/***** CONTENT *****/

#wrapper, body.cmonsite-fluxpanier-2 #main-conteneur.page-panier_commande #wrapper {
  padding-left: 270px;
  box-sizing: border-box;
  padding-top: 0px;

  > .content_p {
    padding: 10px;
    box-sizing: border-box;

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:first-child:last-child {
      border-radius: 8px 8px 8px 8px;
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.accueil_diaporama_template {
  z-index: 99;
}

#wrapper, .header-conteneur,
.accueil_diaporama_template_1,
.accueil_diaporama_template_3,
.accueil_diaporama_template_5,
.accueil_diaporama_template_6 {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  //z-index: 50;
  box-sizing: border-box;
}

.content {
  margin-top: 0px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

/***** SLIDER ACCUEIL *****/

.wrapper.accueil_diaporama_template {
  .slide .slide-text .content-slide-text > a.slider-see-more {
    margin: 0;
    left: 20px;
    right: auto;
  }

  a.bx-next {
    right: 20px;
  }

  a.bx-prev {
    left: 20px;
  }

  &.accueil_diaporama_template_1 {
    .bx-wrapper {
      margin-bottom: 0;

      .bx-pager {
        position: relative;
        bottom: auto;
        width: 100%;
      }
    }
  }

  &.accueil_diaporama_template_2 {
    .bx-wrapper {
      margin-bottom: 0;
    }

    .bx-pager-item {
      display: none;
    }
  }

  &.accueil_diaporama_template_1,
  &.accueil_diaporama_template_3,
  &.accueil_diaporama_template_5,
  &.accueil_diaporama_template_6 {
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
    padding: 10px 0 0 282px;
    box-sizing: border-box;

    .bx-wrapper {
      -moz-border-radius: 8px 8px 0 0;
      border-radius: 8px 8px 0 0;
      padding: 15px;
    }

    ~ #wrapper {
      padding-top: 0;

      > .content_p:first-child:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
  }

  &.accueil_diaporama_template_5 {
    margin: 0 auto;

    .bx-wrapper {
      margin: 0;
    }

    .slide .slide-text .content-slide-text {
      .addcommentaires {
        top: 0;
        right: 0;
      }

      > a.slider-see-more {
        top: 0;
        left: 0;
        right: auto;
      }

      p {
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 0;
      }

      > a.slider-see-more, > p {
        font-size: 12px;
        text-transform: none;
      }
    }
  }
}

/***** DIAPORAMA *****/

.diaporama {
  text-align: center;
  text-decoration: none;
  margin-top: 0;
  padding-top: 20px;
}

.diaporama a {
  text-decoration: none;
}

.diaporama a:hover {
  text-decoration: underline;
}

/*-----------------------------
    HEADER
-------------------------------*/

.slogan {
  min-height: 20px;
  font-size: 2.5em;
  font-family: 'Yanone Kaffeesatz', arial, serif;
  z-index: 9999;
  position: absolute;
  left: 295px;
  bottom: 25px;
  margin-bottom: 0 !important;
  width: auto;
  display: inline-block;
}

.logo {
  position: absolute;
  z-index: 9999;
  top: 0;
  display: inline-block;

  img {
    max-height: 130px;
    height: auto !important;
    max-width: 240px;
  }
}

header {
  position: relative;
  margin: 0;
  padding: 15px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../images/imgd242series/header-bg.png) repeat-x left top;
  }
}

.header-conteneur {
  z-index: 1050;
}

#headercontent {
  position: relative;
  height: 130px;
  width: 1080px;
  margin: auto;

  h1, .h1 {
    z-index: 150;
  }

  a {
    text-decoration: none;
    font-family: Ubuntu, arial, sans-serif;
  }
}

/***** NAVIGATION *****/

//Fil d'ariane

.nav-static-breadcrumb {
  margin-top: 5px;
  margin-bottom: 15px;

  li {
    position: relative;

    &:not(:last-child) {
      margin-right: 8px;

      &::after {
        content: '';
        display: block;
        width: 8px;
        position: absolute;
        left: 100%;
        top: 50%;
        height: 1px;
        background: rgba(0, 0, 0, .07);
        margin: 0;
        transition: all .2s linear;
      }
    }

    a {
      padding: 8px 12px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      transition: all .2s linear;
      border: 1px solid rgba(0, 0, 0, .07);
      display: inline-block;

      &.actif {
        border: 1px solid rgba(0, 0, 0, .3);
        background: rgba(0, 0, 0, .03);
      }
    }

    &:hover {
      > a {
        border: 1px solid rgba(0, 0, 0, .1);
        background: rgba(0, 0, 0, .03);
      }

      &::after {
        background: rgba(0, 0, 0, .1);
      }
    }
  }
}

//Arrondis en bas de page
#menu {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  top: -45px;
}

#navigation-bottom, #menu-bottom {
  width: 100%;
  height: 55px;
  z-index: 100;
  position: relative;
  overflow: hidden;
  margin-left: 0;

  &::before {
    content: '';
    border-radius: 50%;
    width: 430px;
    height: 430px;
    position: absolute;
    bottom: 11px;
    z-index: 20;
    left: -34%;
    box-sizing: border-box;
  }
}

#menu-top {
  height: 55px;
  width: 100%;
  box-sizing: border-box;
}

#bordLeft {
  float: left;
  width: 100%;
  height: 55px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    left: -34%;
    bottom: 0;
    width: 430px;
    height: 215px;
  }
}

//Navigation principale
header .menu {
  position: absolute;
  top: -15px;
  width: 256px;
  box-sizing: border-box;
  z-index: 1050;
}

#nav-principal {
  width: 100%;
  top: 0;
  z-index: 100;
  margin: auto;
  padding: 160px 0 0;
  box-sizing: border-box;
  position: relative;

  ul {
    box-sizing: border-box;
    margin-top: 0;
    margin-left: 0;
    padding-left: 10px;
    width: 100%;
    max-width: 100%;
  }

  li {
    list-style-type: none;
    background-image: none;

    &:hover {
      background-image: none !important;

      > a::before {
        font-weight: bold;
      }
    }
  }

  a {
    font-family: Ubuntu, arial, sans-serif;
    font-size: 1.3em;
    letter-spacing: .1em;
    text-decoration: none;
    display: block;
    padding: 10px 10px 10px 30px;
    background-image: none;

    &:not(.menu_burger)::before {
      content: "\f105";
      color: inherit;
      font-family: fontAwesome, sans-serif;
      font-size: 1rem;
      position: absolute;
      left: 10px;
      top: 12px;
    }
  }
}

/***** ACCUEIL *****/

#boutiqueaccueil {
  margin-top: 20px;
  clear: both;
}

/***** BOUTIQUE *****/

.produits {
  margin-top: 30px;
  margin-bottom: 25px;
  text-align: center;
  position: relative;
  border-bottom: none;
  padding: 10px;
  box-sizing: border-box;

  img {
    margin: 0;
    box-sizing: border-box;
  }

  p {
    text-align: center;
    font-size: .85em;
    margin: 5px 0;
  }

  select {
    width: 100%;
  }

  .attr {
    font-weight: 700;
    font-size: .85em;
  }

  .nomprod {
    font-weight: 700;
    font-size: .9em;
    line-height: 1em;
  }

  .titreprod {
    font-size: .9em;
    line-height: 1em;
    height: 30px;
    font-style: italic;
    font-family: arial, sans-serif;
  }

  .prix {
    text-align: center;

    .prixprod {
      font-weight: 700;
      font-size: 1.1em;
    }

    .oldprix, .prixprod {
      float: none;
      display: inline-block;
    }

    .oldprix {
      text-decoration: line-through;
      color: red;
    }
  }

  .remise {
    position: absolute;
    width: 56px;
    height: 40px;
    background: url(/images/vignette_promo.png) no-repeat bottom left;
    font-size: 10px;
    top: 0;
    padding-top: 80%;
    padding-top: calc(100% - 40px);
    color: white;
    right: -15px;
    z-index: 10;
    font-weight: bold;

    &::before {
      content: 'REMISE';
      display: block;
      font-weight: normal;
    }
  }

  .addbasket {
    cursor: pointer;
    display: block;
    margin: 10px auto;
    padding: 5px;
    text-transform: none;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;

    &:hover {
      text-decoration: none;
    }
  }

  .produit_etiquette, .produit_etiquette2, .produit_etiquette3 {
    height: auto;
    width: 100%;
    left: 0;
    padding: 5px 0;

    h3, .h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 13px;
    }

    &::after {
      margin-left: -9px;
      border-width: 9px;
    }
  }

  &.is_etiquette .remise {
    top: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 20px;
  }
}

.template_boutique_accueil {
  .produits {
    .addbasket {
      padding-left: 25px;
      margin: 10px auto;
    }
  }

  &.accueil_boutique_template_1 {
    .bx-wrapper .bx-viewport {
      padding-bottom: 35px;
    }

    .produits {
      .remise {
        left: auto;
        right: 0;
      }

      .produit_etiquette {
        left: 0;
      }

      .addbasket {
        padding: 5px;
        font-size: 11px;
      }
    }
  }

  &.accueil_boutique_template_2 .produits {
    .remise {
      right: -15px;
      left: auto;
    }

    .addbasket {
      padding: 5px;
      font-size: 11px;
    }
  }
}

.template_boutique {
  .produits {
    margin-top: 30px;
    margin-bottom: 25px;

    .block-btn-addbasket {
      margin: 10px 0;
    }

    .addbasket {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .hors-stock {
      margin: 10px 0;
    }
  }

  &.template_boutique_1 .produits {
    .addbasket, .attr, .hors-stock {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 70%;
      float: none;
      position: static;
    }
  }

  &.template_boutique_2 .produits {
    .addbasket, .hors-stock {
      width: 50%;
    }

    .attr {
      float: none;
      width: 45%;
      margin: 15px auto;

      select {
        width: 100%;
        right: auto;
      }
    }

    .productContentWrap {
      padding: 0 5px;
    }
  }

  &.template_boutique_3 .produits {
    margin-bottom: 40px;

    .attr select {
      float: none;
    }

    .addbasket {
      position: relative;
      bottom: auto;
      max-width: 185px;
      margin: 10px auto;
    }

    .remise {
      padding-top: 50px;
      left: 25%;
    }

    &.is_etiquette .nomprod {
      margin-top: -25px;
    }
  }

  &.template_boutique_4 {
    .produits {
      @include elemTailleCol(3, 1.25, true);

      .attr {
        box-sizing: border-box;

        select {
          width: 100%;
          left: auto;
        }
      }

      .addbasket {
        margin: 10px auto;
        background-image: none;
        padding: 5px;
      }
    }
  }

  &.template_boutique_5 .produits {
    .addbasket {
      width: 100%;
    }

    .remise {
      padding-top: 16px;
      top: auto;
      right: -25px;
      left: auto;
      bottom: -27px;
    }
  }

}

.ajouter-panier .addbasket, #fiche-produit .top_fiche .addbasket {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  padding: 15px 5px 15px 20px;
  text-transform: none;
  min-width: 110px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
}

//Arrondis sous produits + galerie
.produits-bottom, .galerie-bottom {
  height: 27px;
  overflow: hidden;
  width: 100%;
  position: absolute;
  bottom: -25px;
  left: 0;

  &::after {
    content: '';
    width: 108%;
    display: block;
    border-bottom: 50px solid #000;
    height: 27px;
    position: absolute;
    top: -50px;
    border-radius: 100%;
    left: -4.5%;
  }

  &:hover {
    text-decoration: none;
  }
}

//Fiches produits

#fiche-produit {
  .ajouter-panier {
    position: relative;
  }

  .remise-produit {
    position: absolute;
    top: auto;
    border-radius: 0;
    line-height: 35px;
    height: 35px;
    width: auto;
    padding: 0 7px;
  }

  .wrap-description {
    h1, .h1 {
      margin-top: 0;
    }
  }

  .top_fiche {
    .button.addbasket {
      width: auto;
      margin: 10px auto;
      display: inline-block;
      cursor: pointer;
      background-position: 7px center;
      text-transform: none;
      min-width: 110px;
      text-align: center;
      text-decoration: none;
      box-sizing: border-box;
      padding: 7px 11px 7px 0;
      font-size: 15px;

      i {
        padding: 5px 11px;
        margin-right: 11px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .button {
      margin: 10px;
      display: inline-block;
      width: auto;
    }

    .wrap-description {
      .declinaison .col label {
        margin: 10px;
      }

      .prix {
        .prixprod {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
}

.cmonsite-ficheproduit-1,
.cmonsite-ficheproduit-2 {
  #bloc-fil-tgititle {
    h1 {
      display: none;
    }
  }
}

.template_fiche_produit {
  #fiche-produit {
    .titre-etiquette {
      padding: 8px 0;
    }
  }

  &.template_fiche_produit_1 #fiche-produit {
    .remise-produit {
      display: none;
    }

    .ajouter-panier {
      .remise-produit {
        display: block;
        position: absolute !important;
        top: 0;
        right: 0;
      }
    }

    .triangle-ficheprod-second {
      display: block !important;
    }
  }

  &.template_fiche_produit_2 #fiche-produit {
    .wrap-images {
      .triangle-ficheprod {
        display: none;
      }
    }

    .triangle-ficheprod-second {
      display: block !important;
      position: static;
      width: 100%;
      height: auto;
    }

    .remise-produit {
      display: block;
      margin-top: 5px;
      margin-bottom: -8px;
      position: static;
      width: 100%;
      height: auto;
    }
  }

  &.template_fiche_produit_3 #fiche-produit {
    .remise-produit {
      top: 0;
    }

    .prix {
      float: none;
      width: 100%;
      clear: both;
    }

    .wrap-description .col-sm-3.side-tabs, .wrap-description .col-sm-9 {
      width: 100%;
    }

    .wrap-description .col-sm-3.side-tabs a {
      margin-top: 0;
      padding: 3px 10px;
      display: inline-block;
      width: auto;
    }

    .top_fiche > h1,
    .top_fiche > .h1 {
      display: none;
    }
  }
}

#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > .button {
    flex: 0 1 auto;
    margin: 15px 0;
    display: inline-block;
    padding: 7px 11px 7px 11px;
    font-size: 15px;

    .fa {
      margin-right: 10px;
    }
  }

  > .button + .button {
    margin-left: 15px;
  }

}

.modal {
  z-index: 2041;
}

.modal-backdrop {
  z-index: 2040;
}

/***** GALERIE *****/

.galerie {
  position: relative;
  float: left;
  text-align: center;
  box-sizing: border-box;

  .center-img {
    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgb(255, 255, 255);
      opacity: 0;
      transition: all .2s linear;
      z-index: 3;
    }
  }

  .bloc-titre-galerie {
    z-index: 10;
  }

  .commentP {
    z-index: 10;
  }

  .mask {
    background: transparent !important;
  }

  .addcommentaires {
    z-index: 10;
  }

  a {
    font-family: arial, sans-serif;
    text-decoration: none;
  }

  &:hover {
    .center-img {
      &::after {
        opacity: .5;
      }
    }
  }
}

.template_album {
  &.template_album_1 {
    .galerie {
      padding-top: calc(32% - 2px);
      border: 1px solid transparent;
    }
  }

  &.template_album_2 .galerie {
    border: none;
  }

  &.template_album_3 .galerie {
    border: none;

    .galerie-photo-bloc a {
      left: 0;
    }
  }
}

/***** LIVRE D'OR *****/

.message_lo {
  position: relative;
  background: url(../images/imgd242series/border_texture.gif) repeat;
  font-family: arial, sans-serif;
  text-align: left;
  margin: 20px 0;
  padding: 15px;

  img {
    display: inline;
  }

  p {
    text-align: justify;
    margin-bottom: 5px;
    font-family: arial, sans-serif;
  }

  h4, .h4 {
    color: inherit
  }

  .message {
    //font-style:italic;
    font-size: 1em;
  }

  .web {
    text-align: justify;
    font-size: .8em;
    margin: 10px 0 5px;
  }
}

.template_livreor {
  a.addmsglo {
    margin: 20px auto;
    font: 1em normal arial, sans-serif;
    text-transform: uppercase;
    display: block;
    width: 330px;
    text-decoration: none;
    text-align: center;
    padding: 5px;
  }

  &.template_livreor_1 {
    .message_lo {
      .note {
        float: right;
      }

      h4, .h4 {
        float: left;
        max-width: 80%;
      }

      .message {
        clear: both;
      }
    }
  }

  &.template_livreor_3 {
    .message {
      &::before, &::after {
        font-style: italic;
      }
    }
  }
}

/***** CONTACT *****/

.form {
  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  p {
    font-family: Ubuntu, arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: .15em;
    margin: .5em 0;
  }

  label {
    font: 11px arial, sans-serif;
    margin: 5px 3px 3px;
  }

  input, textarea {
    font-family: Ubuntu, arial, sans-serif;
    margin: 3px;
    padding: 5px;
  }

  textarea {
    height: 125px;
  }

  input.button, .button {
    text-transform: uppercase;
    font: 1em normal arial, sans-serif;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    padding: 5px;
    display: inline-block;
    margin: 10px;
  }
}

.template_contact {
  &.template_contact_2 {
    .btnCapValid {
      > div {
        width: 100%;
      }
    }
  }

  &.template_contact_4 {
    .form-group {
      label {
        display: none;
      }

      input {
        width: 100%;
      }

      textarea {
        width: 100%;
      }
    }

  }
}

/*-----------------------------
    PANIER
-------------------------------*/

.cmonsite-panier-2 nav#nav-principal > ul {
  max-width: 100%;
}

body.cmonsite-panier-2 .template-panier.template-panier-2 {
  width: 100%;
  float: none;
  padding-left: 10px;
  box-sizing: border-box;

  #panier {
    #header-panier {
      padding-left: 30px;

      &::before {
        content: "\f105";
        color: inherit;
        font-family: fontAwesome, sans-serif;
        font-size: 1rem;
        position: absolute;
        left: 10px;
        top: 12px;
      }

      .count-live {
        left: 40px;
      }
    }

    #paniercontent {
      right: auto;
      top: auto;
      left: 100%;
      bottom: 0;
    }

    .paniertoggle {
      padding: 0;
    }
  }

  &:hover {
    #panier #header-panier::before {
      font-weight: bold;
    }
  }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

body .template_flux_panier_2 {
  .block-resume, .block-information, .recapitulatif.recap-l, .recapitulatif.recap-r {
    width: 100%;
  }

  .form-order::before, .block-finish::before {
    display: none;
  }

  #resume, .recapitulatif.recap-l {
    background: rgba(0, 0, 0, 0.02);
  }
}

/*-----------------------------
    FOOTER
-------------------------------*/

footer {
  clear: both;
  text-align: center;
  margin: 20px 0 0;
  padding: 10px 0 10px;
  background-repeat: repeat !important;

  a, a:hover {
    text-decoration: none;
  }

  #pub {
    padding: 35px;
    box-sizing: border-box;
    width: 100%;
    margin: 10px auto;
  }
}

/*-----------------------------
    AUTRE
-------------------------------*/

#connexion-inscription {
  h2, .h2 {
    font-size: 1.5em;
  }

  .button {
    max-width: 100%;
  }

  .connexion {
    .button {
      margin-top: 10px !important;
    }
  }
}

#resume table + div .col.s6:first-child label {
  display: block;
  width: 100%;
  margin-bottom: 3px;
}

#contentpage .side-tabs.etapes ul {
  margin-left: 0;
  margin-right: 0;
}

.content #resume table img {
  max-height: 150px;
  max-width: 150px !important;
  width: auto !important;
}

#savoirplus h4, .savoirplus .h4 {
  margin: 15px 0 !important;
}

.iview-perso {
  min-width: 0 !important;
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
  .side-tabs.etapes,
  div#resume .block-recap-panier,
  div#resume div.next-step,
  div#information #connexion-inscription,
  div#information div.next-step,
  #infoscommande,
  div#finish .block-info-payment,
  div#finish div.next-step,
  div#resume h4,
  div#finish h4,
  .message-commande {
    width: 100%;
  }

  h4 {
    margin: 0;
  }

  div#resume .block-resume-price .col.s6,
  div#finish .block-resume-price .col.s6 {
    width: 100%;
  }

  div#resume .block-price .block-with-background,
  div#finish .block-price .block-with-background {
    border-left: none;
  }

  #connexion-inscription > .col.s6 {
    padding: 0;
    width: 100%;
    margin: 15px 0;
  }

  div#information .list_address {
    margin: 0 0 20px;
  }
}

/* Commandes */

#order .commandes td {
  padding: 10px 0;
  border-top: 1px solid rgba(128, 128, 128, .1);

  .button {
    margin: 0 5px 0 0 !important;
  }
}

#order .commandes-details {
  > td > div {
    border-bottom: none;
  }

  .infosclient.s6 {
    &, &:first-child {
      width: 100%;
      margin: 10px 0 0;
    }
  }
}

/*-----------------------------
    MEDIA QUERIES
-------------------------------*/

@media #{$large-and-up}{
  .template_boutique_accueil {
    &.accueil_boutique_template_1 {
      $largeurFleches: 70px;
      $margin: 20px;
      $nbrProduit: 3;
      @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
    }
  }
}

@media #{$medium-and-down} {
  .slogan .p {

    font-size: 16px !important;

    a {
      font-size: 16px !important;
    }
  }

  .template_album.template_album_1 .galerie {
    padding-top: calc(48% - 2px);
  }

  .search-bar .search-bar-icon {
    width: 7%;
  }
  #connexion-inscription {
    .col.s6 {
      width: 100%;
    }

    .button {
      font-size: 15px;
    }
  }
  #infoscommande input[type=text], #infoscommande textarea {
    box-sizing: border-box;
  }
  .content img {
    height: auto;
  }
  .content .form-newsletter input[type=text] {
    width: 100%;
  }
  .content .form-newsletter input[type=submit] {
    display: block;
    margin: 3px auto;
  }
  #headercontent {
    width: 100%;

    a {
      max-width: 100%;
      left: 2% !important;
    }
  }

  #wrapper, .header-conteneur,
  .accueil_diaporama_template_1,
  .accueil_diaporama_template_3,
  .accueil_diaporama_template_5,
  .accueil_diaporama_template_6 {
    max-width: 768px;
  }
  .accueil_diaporama_template_1,
  .accueil_diaporama_template_3,
  .accueil_diaporama_template_5 {
    .bx-wrapper {
      padding: 10px;
      box-sizing: border-box;
    }
  }
  .header-conteneur {
    padding: 0 10px;
  }
  #block-flottant-search {
    display: none;
  }
  #nav-principal ul {
    box-sizing: border-box;
    margin-top: 0;
    margin-left: 0;
    padding-left: 10px;
    width: 100%;
  }
  #content, .sliderImage span {
    width: 100%;
    box-sizing: border-box;
  }
  .content #sliderContent {
    width: 100%;
  }
  .search-bar .search-bar-icon {
    width: 10%;
  }
  .message_lo h4,
  .message_lo .h4 {
    width: 92%;
  }
  #footer-content {
    width: 100%;
  }
  #connexion-inscription form .col {
    width: 100%;
  }
  .template_boutique {
    &.template_boutique_2 {
      .produits {
        .attr {
          float: none;
          clear: both;
          width: 95%;
        }
      }
    }

    &.template_boutique_3 .produits {
      .remise {
        left: 85%;
      }
    }

    &.template_boutique_4 .produits {
      @include elemTailleCol(2, 1.25, true);
    }
  }
}

@media screen and (max-width: 768px) {
  .template_boutique {
    &.template_boutique_4 {
      .produits {
        @include elemTailleCol(1, 1.25, true);
      }
    }
  }
}

@media #{$small-and-down} {
  /* Tableau responsive */
  @import 'design/helper/responsive/mobile/mobile';

  .template_boutique_accueil {
    &.accueil_boutique_template_1 {
      .produits {
        .remise {
          right: 5px;
        }
      }
    }
  }

  #wrapper .template_album_1 .list-photos .galerie {
    padding-top: calc(100% - 2px) !important;
  }
  .template_boutique {
    .produits {
      .addbasket {
        float: none;
        display: inline-block;
        width: auto;
      }

      .attr {
        width: 100%;
        float: none;
        text-align: center;

        select {
          display: inline-block;
        }
      }
    }
  }

  #nav-principal li a, div#menu ul li a {
    font-size: 1.2em;
  }
  #wrapper, .header-conteneur,
  .accueil_diaporama_template_1,
  .accueil_diaporama_template_3,
  .accueil_diaporama_template_5,
  .accueil_diaporama_template_6 {
    max-width: 500px;
    width: 100%;
    padding: 10px !important;
  }
  .accueil_diaporama_template_1,
  .accueil_diaporama_template_3,
  .accueil_diaporama_template_5 {
    margin-top: 15px;
  }
  div#menu {
    bottom: -55px;
  }
  .content #slider {
    height: 300px;
  }
  .content #slider img {
    max-height: 300px;
  }
  #contentpage_center .produits {
    height: 300px;
  }
  #fiche-produit #formulaire .form-group {
    padding: 0 5px;
  }
  .search-bar .search-bar-icon {
    width: 12%;
  }
  #resume .button, #information .button {
    font-size: 11px;
  }
  .side-tabs.etapes.top a, .side-tabs.etapes.top li:nth-child(3) span {
    padding: 3px 8px 3px 18px;
    font-size: 13px;
  }
  .content #resume table img {
    max-width: 100% !important;
  }
  #contentpage .content-tab ul {
    margin-left: 0;
    margin-right: 0;
  }
  #content {
    padding: 15px;
  }
  .content #slider {
    height: 290px;
  }
  .content #slider img {
    max-height: 290px;
  }
  #content_full #fiche-produit .top_fiche .button {
    display: block;
    margin: 10px 0;
  }
  .message_lo h4,
  .message_lo .h4 {
    width: 90%;
  }
  form > div > .col.s4 {
    padding: 0;
  }
  .side-tabs.etapes.top a, .side-tabs.etapes.top li:nth-child(3) span {
    padding: 3px 5px 3px 18px;
    font-size: 12px;
  }
  #information #infoscommande > .row .col.s3 {
    width: 30%;
  }
  #headercontent {
    height: 130px;
    padding-left: 145px;
    box-sizing: border-box;
    z-index: initial;
    padding-bottom: 0 !important;
  }
  .cmonsite-panier-3 #blocContent .template-panier.template-panier-3 .bloc-connexion-panier #panier #paniercontent {
    right: 0 !important;
    left: auto !important;
    margin-top: 0 !important;
  }
  body .wrapper.accueil_diaporama_template_5 .slide .slide-text .content-slide-text > a.slider-see-more {
    top: 20px;
  }
  #logo {
    top: 0 !important;
    font-size: 28px !important;
    left: 0 !important;
    right: auto !important;
    width: 100% !important;
    text-align: center !important;
    box-sizing: border-box !important;
    z-index: 20 !important;
    bottom: auto !important;
    max-width: 125px !important;
    position: absolute !important;

    img {
      position: static !important;
      left: auto !important;
      right: auto !important;
      top: auto !important;
      bottom: auto !important;
      max-width: 100% !important;
      max-height: 110px !important;
      height: auto !important;
      width: auto !important;
    }
  }
  .slogan_live_edit .slogan_p {
    bottom: 0 !important;
    font-size: 28px !important;
    left: 0 !important;
    right: auto !important;
    width: 100% !important;
    text-align: center !important;
    box-sizing: border-box !important;
    z-index: 1 !important;
    position: relative !important;
  }
  body.cmonsite-panier-3 #blocContent .template-panier.template-panier-3 {
    margin-top: 15px;
    margin-bottom: 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    z-index: 1 !important;
    position: relative;

    .bloc-connexion-panier {
      #header-panier {
        text-align: center;
      }

      .count-live {
        left: calc(100% / 2 - 35px);
      }

      .connexion {
        width: 100% !important;
        padding-right: 0 !important;

        p {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 5px;
          border-right-width: 0 !important;
          border-bottom: 1px solid;
          text-align: center;
        }
      }

      #panier {
        width: 100% !important;
      }
    }
  }
  body.cmonsite-panier-2 .template-panier.template-panier-2 {
    position: static;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;

    #panier {
      #header-panier {
        text-align: left;
      }

      .count-live {
        left: 45px;
      }

      #paniercontent {
        box-sizing: border-box;
        position: relative;
        min-width: 200px;
        width: 100%;
        width: calc(100% + 10px);
        left: -10px !important;
        right: auto !important;
        bottom: auto !important;
        top: auto !important;
      }
    }
  }

  #wrapper, .header-conteneur,
  .accueil_diaporama_template_1,
  .accueil_diaporama_template_3,
  .accueil_diaporama_template_5,
  .accueil_diaporama_template_6 {
    padding: 0 6px !important;
  }
  div#menu {
    bottom: -45px;
  }
  #nav-principal ul, div#menu ul {
    padding-left: 3px;
  }
  #nav-principal li a, div#menu ul li a {
    font-size: 1.05em;
    padding: 10px 10px 10px 25px;
  }
  .content #slider {
    height: 280px;
  }
  .content #slider img {
    max-height: 280px;
  }
  .content #fiche-produit .wrap-images {
    float: none;
    width: 85%;
    max-width: 250px;
    margin: auto;
  }

  .zoomPad:hover {
    cursor: default;
  }
  .zoomPad:hover img {
    opacity: 1 !important;
  }
  .zoomPup, .zoomWindow {
    display: none !important;
  }
  #content_full #fiche-produit .wrap-description {
    float: none;
    width: 100%;
    margin-top: 20px;
  }
  #fiche-produit #formulaire .form-group {
    width: 100%;
  }
  .search-bar .search-bar-icon {
    width: 15%;
  }
  textarea {
    width: 100% !important;
  }
  .row .col.s3 {
    width: 25%;
  }
  #content a.addmsglo {
    width: 300px;
  }
  .message_lo h4,
  .message_lo .h4 {
    width: 87%;
  }
  #headercontent a {
    left: 0 !important;
    top: 0;
  }

  /* Nav mobile */
  header .menu {
    z-index: 0;
    width: 95%;
    max-width: 300px;

    &.menu-ouvert {
      z-index: 1;
    }
  }
  #nav-principal {
    padding-top: 120px;
    width: 100%;
    max-width: 125px;
    margin: 0;
    transition: max-width .2s linear;
    border: none;

    &.nav_ouverte {
      max-width: 300px;

      > ul {
        display: block;
        width: 100%;
      }
    }

    > ul {
      display: none;
    }

    ul {
      padding-top: 0;
    }

    li {
      margin-bottom: 0;
      border-bottom: 1px solid #ccc;
      position: relative;

      ul {
        ul {
          display: block;
          position: relative;
          text-align: left;
          border: none;
        }

        li {
          padding-left: 15px;
        }
      }
    }

    .hasDropdown ul + span::after {
      font-family: FontAwesome, sans-serif;
      content: "\f0d7";
    }

    .dropDown {
      position: absolute;
      right: 2%;
      top: 0;
      font-weight: bold;
      display: block;
      height: 28px;
      width: 8%;
      cursor: pointer;
      text-align: center;
      font-size: 1.2rem;
      padding-top: 5px;
    }
  }
  .menu_burger {
    cursor: pointer;
    display: block;
    padding: 7px 12px 7px;
    font-size: 1.15em;
    text-transform: uppercase;

    &::before {
      font-family: FontAwesome, sans-serif;
      content: "\f0c9";
      margin-right: 5px;
    }

    &:hover {
      background-image: none !important;
    }
  }
  #navigation-bottom, div#menu-top {
    display: none;
  }

  header .menu #nav-principal, header .menu #menu {
    box-shadow: 0 4px 2px rgba(11, 25, 28, 0.3);
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=174, Strength=10);
  }

  /* Fin Nav mobile */

  div#right {
    width: 100%;
    margin-right: 0;
  }
  #content {
    padding: 30px 15px 15px;
  }
  .search-bar .search-bar-icon {
    width: 7%;
  }
  .message_lo h4,
  .message_lo .h4 {
    font-size: .9em;
  }

  #connexion-inscription .connexion {
    margin-top: 30px;
  }
  #block-flottant-search {
    display: none;
  }
  div#menu {
    top: 0;
  }
  #menu-bottom {
    display: none;
  }

  #navigation-bottom, #navigation-bottom + div {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  #wrapper, .header-conteneur,
  .accueil_diaporama_template_1,
  .accueil_diaporama_template_3,
  .accueil_diaporama_template_5,
  .accueil_diaporama_template_6 {
    width: 95%;
  }
}

@media screen and (max-width: 400px) {
  #minisite-sidebar {
    display: none;
  }
  body .template-panier-1 #panier {
    width: 80%;
    right: 7%;
  }
  .content #totalbloc {
    width: 100%;
    box-sizing: border-box;
  }

  #nav-principal.nav_ouverte {
    max-width: 100%;
  }
}

@media screen and (max-width: 360px) {
  nav#nav-principal {
    padding-top: 130px;
  }
  #content {
    padding: 10px;
  }
  .content #slider {
    height: 230px;
  }
  .content #slider img {
    max-height: 230px;
  }
  .produits .prix + div {
    width: 50px !important;
    right: -7px !important;
    top: 105px !important;
  }
  .produits a.addbasket {
    margin-left: 3px;
    padding: 5px 5px 5px 16px;
  }
  .row .col.s3 {
    width: 50%;
  }
  form .button {
    margin: 10px 15px;
  }
  .message_lo h4,
  .message_lo .h4 {
    font-size: .8em;
  }
  .contact form .button {
    margin: 0 !important;
  }
}

@media screen and (max-width: 320px) {
  .content #slider {
    height: 220px;
  }
  .content #slider img {
    max-height: 220px;
  }
}

@media #{$medium-and-up}{
  #nav-principal {
    > ul {
      ul {
        transform-origin: top left;
        position: absolute;
        padding-left: 0 !important;
      }
    }

    ul > li {
      > ul {
        height: 0;
        top: 0;
        left: 100%;
        overflow: hidden;
        transform: scaleX(0);
        transition: transform .2s linear;
      }

      &:hover > ul {
        height: auto;
        overflow: visible;
        transform: scaleX(1);
      }
    }

    li {
      position: relative;

      li {
        float: none;
        display: block;

        > ul {
          width: 0;
          transform: scaleX(0);
          transition: transform .2s linear;
          left: 100%;
          top: 0;
          height: auto !important;
        }

        &:hover > ul {
          width: 100%;
          overflow: visible;
          transform: scaleX(1);
        }
      }
    }
  }
}

.wrap-description .bloc-quantite .bloc-bt-quantity .bt-quantity {
  height: auto;
}

.template_fiche_produit_2, .template_fiche_produit_3 {
  #fiche-produit .bloc-quantite {
    flex-direction: column;
  }
}

.template_fiche_produit_3 #fiche-produit .bloc-quantite {
  margin-bottom: 10px;
}